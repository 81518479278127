<div class="games-container" style="min-height: 500px">
  <div class="mat-elevation-z0">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortField"
      [matSortDirection]="sortDirection"
      matSortDisableClear="true"
      [formGroup]="filterForm"
      (matSortChange)="sortingChanged($event)"
    >
      <ng-container matColumnDef="date-filter">
        <th mat-header-cell *matHeaderCellDef>
          <input
            placeholder="Date"
            formControlName="date"
            style="width: 75px"
          />
          <mat-checkbox style="margin-left: 8px" [formControl]="today"
            >Today
          </mat-checkbox>
        </th>
      </ng-container>
      <ng-container matColumnDef="date">
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          matTooltip="Game date in your local timezone"
        >
          Date&nbsp;({{ currentDate | date : 'z' }})
        </th>
        <td mat-cell *matCellDef="let game">
          <span>{{ game.date | date : 'yyyy-MM-dd' }}</span>
          <span style="color: #939393"> {{ game.date | date : 'HH:mm' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="venueLocalDate-filter">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="venueLocalDate">
        <th
          *matHeaderCellDef
          mat-header-cell
          matTooltip="Game date in venue's local timezone"
        >
          Venue Date
        </th>
        <td mat-cell *matCellDef="let game">
          <ng-container *ngIf="game.venueUTCOffset">
            <span>{{
              game.date | date : 'yyyy-MM-dd' : game.venueUTCOffset
            }}</span>
            <span style="color: #939393">
              {{ game.date | date : 'HH:mm' : game.venueUTCOffset }}</span
            >
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="insertDate-filter">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="insertDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Insert Date</th>
        <td mat-cell *matCellDef="let game">{{
          game.insertDate | date : 'yyyy-MM-dd'
        }}</td>
      </ng-container>

      <ng-container matColumnDef="season-filter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-select
            formControlName="season"
            name="season"
            placeholder="Season"
          >
            <mat-option>Any</mat-option>
            <mat-option *ngFor="let season of seasons | async" [value]="season"
              >{{ season - 1 }}/{{ season }}</mat-option
            >
          </mat-select>
        </th>
      </ng-container>
      <ng-container matColumnDef="season">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Season</th>
        <td mat-cell *matCellDef="let game">{{ game.season }}</td>
      </ng-container>

      <ng-container matColumnDef="seasonType-filter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-select
            formControlName="seasonType"
            name="seasonType"
            placeholder="Season Type"
          >
            <mat-option>Any</mat-option>
            <mat-option
              *ngFor="let seasonType of seasonTypes"
              [value]="seasonType"
              >{{ seasonType }}</mat-option
            >
          </mat-select>
        </th>
      </ng-container>
      <ng-container matColumnDef="seasonType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Season Type</th>
        <td mat-cell *matCellDef="let game">{{ game.seasonType }}</td>
      </ng-container>

      <ng-container matColumnDef="league-filter">
        <th mat-header-cell *matHeaderCellDef>
          <app-multi-select-all
            label="League"
            [itemList]="leagues"
            formControlName="league"
            keyValue="sihfId"
            keyTitle="shortName"
            keySubTitle="fullName"
          ></app-multi-select-all>
        </th>
      </ng-container>
      <ng-container matColumnDef="league">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>League</th>
        <td mat-cell *matCellDef="let game">{{ leaguesById[game.league] }}</td>
      </ng-container>

      <ng-container matColumnDef="teams-filter">
        <th mat-header-cell *matHeaderCellDef>
          <input
            placeholder="Home Team"
            formControlName="homeTeam"
            style="width: 50px"
          />
          <input
            placeholder="Away Team"
            formControlName="awayTeam"
            style="width: 50px"
          />
        </th>
      </ng-container>
      <ng-container matColumnDef="teams">
        <th mat-header-cell *matHeaderCellDef>Teams</th>
        <td mat-cell *matCellDef="let game"
          >{{ game.homeTeam }} - {{ game.awayTeam }}</td
        >
      </ng-container>

      <ng-container matColumnDef="score-filter">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef>Score</th>
        <td mat-cell *matCellDef="let game">
          <ng-container *ngIf="game.goals || game.homeGoals || game.awayGoals">
            {{ goalsWithPriority(game.homeGoals, game.goals?.home) }} -
            {{ goalsWithPriority(game.awayGoals, game.goals?.away) }}
          </ng-container>
          <span *ngIf="game.overtime" title="Overtime">OT</span>
          <span *ngIf="game.shootout" title="Shootout"> SO</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="flags-filter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-select formControlName="flags" style="width: 100px">
            <mat-option>Any</mat-option>
            <mat-option value="withVideo">Video set</mat-option>
            <mat-option value="withoutVideo">Video missing</mat-option>
            <mat-option value="videoDownloadNew">Video – new</mat-option>
            <mat-option value="videoDownloadComplete"
              >Video – complete</mat-option
            >
            <mat-option value="videoDownloadInProgress"
              >Video – in progress</mat-option
            >
            <mat-option value="videoDownloadFailed">Video – failed</mat-option>
            <mat-option value="withLineup">Lineup complete</mat-option>
            <mat-option value="withoutLineup">Lineup incomplete</mat-option>
            <mat-option value="isTrainingGame">Training</mat-option>
            <mat-option value="notIsTrainingGame">Not Training</mat-option>
            <mat-option value="isSihfLive">SIHF Live Export</mat-option>
            <mat-option value="isLiveDraftEvents"
              >Realtime Collection</mat-option
            >
            <mat-option value="isCVProcessed">CV Processed</mat-option>
            <mat-option value="isLiveCollection">Live Collection</mat-option>
            <mat-option value="isVideoReview">Video Review</mat-option>
            <mat-option value="aggregationComplete">Aggregated</mat-option>
            <mat-option value="notAggregated">Not Aggregated</mat-option>
            <mat-option value="aggregationFailed"
              >Aggregation Failed</mat-option
            >
          </mat-select>
        </th>
      </ng-container>
      <ng-container matColumnDef="flags">
        <th mat-header-cell *matHeaderCellDef>Flags</th>
        <td mat-cell *matCellDef="let game">
          <a
            [routerLink]="[game._id, 'video-player']"
            class="video-icon"
            [ngClass]="game.videos && game.videos[0]?.downloadStatus"
          >
            <mat-icon
              *ngIf="(game.videos ?? []).length > 0"
              [matBadge]="(game.videos ?? []).length"
              matBadgeSize="small"
              matBadgePosition="above after"
              matBadgeColor="primary"
              >videocam</mat-icon
            >
          </a>
          <mat-icon
            *ngIf="game.hasLineup()"
            matTooltip="Lineup in place"
            class="lineup"
            >view_module</mat-icon
          >
          <mat-icon
            *ngIf="game.isCVProcessed"
            class="cv-icon"
            [class.error]="hasCVErrors(game)"
            [class.warning]="hasCVWarnings(game)"
            [matTooltip]="combineCVErrorsAndWarnings(game)"
            >visibility</mat-icon
          >
          <mat-icon
            *ngIf="game.isSihfLive"
            matTooltip="SIHF Live"
            class="sihf-live"
            >live_tv</mat-icon
          >
          <mat-icon *ngIf="game.isLiveCollection" matTooltip="Live Collection"
            >bolt</mat-icon
          >
          <mat-icon
            *ngIf="game.isLiveDraftEvents"
            matTooltip="Realtime Collection"
            >speed</mat-icon
          >
          <mat-icon
            *ngIf="game.isTrainingGame"
            matTooltip="Training Game"
            class="training-game"
            >directions_run</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="dataSet-filter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-select formControlName="dataSet" style="width: 100px">
            <mat-option>Any</mat-option>
            <mat-option value="LITE">Lite</mat-option>
            <mat-option value="LITE_PLUS">Lite+</mat-option>
            <mat-option value="FULL">Full</mat-option>
          </mat-select>
        </th>
      </ng-container>
      <ng-container matColumnDef="dataSet">
        <th mat-header-cell *matHeaderCellDef>Data Set</th>
        <td mat-cell *matCellDef="let game">
          {{ game.dataSet }}
        </td>
      </ng-container>

      <ng-container matColumnDef="eventCollectorName-filter">
        <th mat-header-cell *matHeaderCellDef>
          <input
            placeholder="Collector/Team"
            formControlName="eventCollectorName"
            style="width: 150px"
          />
        </th>
      </ng-container>
      <ng-container matColumnDef="eventCollectorName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef
          >Collector/Team</th
        >
        <td mat-cell *matCellDef="let game" style="font-size: 12px">
          {{ game.eventCollectorName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tags-filter">
        <th mat-header-cell *matHeaderCellDef>
          <app-tag-form-field
            [tags]="filterForm.value['tags']?.split(',')"
            (tagChange)="tagsChange($event)"
          ></app-tag-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="tags">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Tags</th>
        <td mat-cell *matCellDef="let game">
          <mat-chip-listbox style="margin-left: 10px">
            <mat-chip-option
              *ngFor="let tag of game.tags"
              (click)="selectGameTag(tag)"
              style="
                margin: 0 4px 0 0;
                color: #4c4c4c;
                font-weight: normal;
                background: #e6e6e6;
                padding: 3px 6px;
                min-height: 24px;
                font-size: 12px;
                padding: 0px;
              "
            >
              {{ tag }}
            </mat-chip-option>
          </mat-chip-listbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="progress-filter">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef>Progress</th>
        <td mat-cell *matCellDef="let game">
          <table class="stats-table" *ngIf="game.status !== 'new'">
            <tr>
              <td class="event-type"
                ><a
                  [routerLink]="[game._id, 'events']"
                  [queryParams]="{ eventType: 'FOINT' }"
                  >FOINT</a
                ></td
              >
              <td
                ><mat-progress-bar
                  mode="determinate"
                  color="accent"
                  [value]="
                    eventStatsProgress(game, [
                      'face_off',
                      'interruption',
                      'penalty'
                    ])
                  "
                  [matTooltip]="
                    eventStatsProgressSeconds(game, [
                      'face_off',
                      'interruption',
                      'penalty'
                    ]) | mmss
                  "
                  style="width: 120px"
                ></mat-progress-bar
              ></td>
              <td class="number">{{
                eventStatsCount(game, ['face_off', 'interruption', 'penalty'])
              }}</td>
            </tr>
            <tr>
              <td class="event-type"
                ><a
                  [routerLink]="[game._id, 'events']"
                  [queryParams]="{ eventType: 'time_on_ice' }"
                  >TOI</a
                ></td
              >
              <td
                ><mat-progress-bar
                  mode="determinate"
                  color="warn"
                  [value]="eventStatsProgress(game, ['time_on_ice'])"
                  [matTooltip]="
                    eventStatsProgressSeconds(game, ['time_on_ice']) | mmss
                  "
                  style="width: 120px"
                ></mat-progress-bar
              ></td>
              <td class="number">{{
                eventStatsCount(game, ['time_on_ice'])
              }}</td>
            </tr>
            <tr>
              <td class="event-type"
                ><a
                  [routerLink]="[game._id, 'events']"
                  [queryParams]="{ eventType: 'shot' }"
                  >SHOP</a
                ></td
              >
              <td
                ><mat-progress-bar
                  mode="determinate"
                  color="warn"
                  [value]="eventStatsProgress(game, ['shot', 'pass'])"
                  [matTooltip]="
                    eventStatsProgressSeconds(game, ['shot', 'pass']) | mmss
                  "
                  style="width: 120px"
                ></mat-progress-bar
              ></td>
              <td class="number">{{
                eventStatsCount(game, ['shot', 'pass'])
              }}</td>
            </tr>
            <tr>
              <td class="event-type"
                ><a
                  [routerLink]="[game._id, 'events']"
                  [queryParams]="{ eventType: 'videoTag' }"
                  >VOT</a
                ></td
              >
              <td
                ><mat-progress-bar
                  mode="determinate"
                  [value]="eventStatsProgress(game, ['videoTag'])"
                  [matTooltip]="
                    eventStatsProgressSeconds(game, ['videoTag']) | mmss
                  "
                  style="width: 120px"
                ></mat-progress-bar
              ></td>
              <td class="number">{{ eventStatsCount(game, ['videoTag']) }}</td>
            </tr>
            <tr *ngIf="game.isHighlightCollection">
              <td class="event-type"
                ><a
                  [routerLink]="[game._id, 'events']"
                  [queryParams]="{ eventType: 'highlight' }"
                  >HL</a
                ></td
              >
              <td
                ><mat-progress-bar
                  mode="determinate"
                  [value]="eventStatsProgress(game, ['highlight'])"
                  [matTooltip]="
                    eventStatsProgressSeconds(game, ['highlight']) | mmss
                  "
                  style="width: 120px"
                ></mat-progress-bar
              ></td>
              <td class="number">{{ eventStatsCount(game, ['highlight']) }}</td>
            </tr>
          </table>
        </td>
      </ng-container>

      <ng-container matColumnDef="trackingStats-filter">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="trackingStats">
        <th mat-header-cell *matHeaderCellDef>Tracking Stats</th>
        <td mat-cell *matCellDef="let game">
          Tracks: {{ game.trackStats?.tracks }} <br />
          Assignments: {{ game.trackStats?.assignments }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastSync-filter">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="lastSync">
        <th mat-header-cell *matHeaderCellDef>Last Sync</th>
        <td mat-cell *matCellDef="let game">{{
          lastSyncDate(game) | date : 'yyyy-MM-dd hh:mm'
        }}</td>
      </ng-container>

      <ng-container matColumnDef="status-filter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-select formControlName="status" style="width: 100px">
            <mat-option>Any</mat-option>
            <mat-option
              *ngFor="let status of gameStatusOptions"
              [value]="status"
              >{{ status }}</mat-option
            >
          </mat-select>
        </th>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let game">
          <mat-icon
            *ngIf="game.status === gameStatus.NEW"
            matTooltip="New"
            class="status-new"
            >new_releases</mat-icon
          >
          <mat-icon
            *ngIf="
              game.status === gameStatus.IN_COLLECTION ||
              game.status === gameStatus.IN_EXTENDED_COLLECTION
            "
            matTooltip="Collection In Progress"
            class="status-in-collection"
            >cached</mat-icon
          >
          <mat-icon
            *ngIf="game.status === gameStatus.QUALITY_CONTROL"
            matTooltip="Collection Quality Control"
            class="status-quality-control"
            >supervised_user_circle</mat-icon
          >
          <mat-icon
            *ngIf="game.status === gameStatus.COMPLETE"
            matTooltip="Collection Complete"
            class="status-complete"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="game.status === gameStatus.FAILED"
            matTooltip="Collection Failed"
            class="status-failed"
            >cancel</mat-icon
          >
          <mat-icon
            *ngIf="game.status === gameStatus.ABANDONED"
            matTooltip="Collection Abandoned"
            class="status-abandoned"
            >clear</mat-icon
          >
          <mat-icon
            *ngIf="game.aggregationStatus === aggregationStatus.IN_PROGRESS"
            matTooltip="Aggregation In Progress"
            class="status-in_progress"
            >pending</mat-icon
          >
          <mat-icon
            *ngIf="game.aggregationStatus === aggregationStatus.FAILED"
            matTooltip="Aggregation Failed"
            class="status-failed"
            >cancel</mat-icon
          >
          <mat-icon
            *ngIf="game.aggregationStatus === aggregationStatus.COMPLETE"
            matTooltip="Aggregation Complete"
            class="status-complete"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="game.finalization?.status === finalizationStatus.IN_PROGRESS"
            matTooltip="Archiving In Progress"
            class="status-in_progress"
            >pending</mat-icon
          >
          <mat-icon
            *ngIf="game.finalization?.status === finalizationStatus.FAILED"
            matTooltip="Archiving Failed"
            class="status-failed"
            >cancel</mat-icon
          >
          <mat-icon
            *ngIf="game.finalization?.status === finalizationStatus.COMPLETE"
            matTooltip="Archiving Complete"
            class="status-complete"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="requiresTimeSync(game)"
            matTooltip="Cameras are not time synced"
            class="status-failed"
            >timer_off</mat-icon
          >
          <mat-icon
            *ngIf="game.hasWarnings"
            class="status-warning"
            matTooltip="Game has Warnings"
            >warning</mat-icon
          >
          <mat-icon
            *ngIf="game.hasErrors"
            class="status-failed"
            matTooltip="Game has Errors"
            >error</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="actions-filter">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-button class="reset-filter-button" (click)="resetFilter()"
            >Reset All Filters</button
          >

          <mat-menu #matMenuSettings="matMenu">
            <ng-template matMenuContent>
              <ng-container *ngFor="let col of columns">
                <button
                  mat-menu-item
                  *ngIf="col && col !== 'actions'"
                  (click)="
                    toggleColumnVisibility(col); $event.stopPropagation()
                  "
                >
                  <mat-checkbox [checked]="displayedColumns.includes(col)">
                    {{ col }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </ng-template>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="matMenuSettings">
            <mat-icon>settings</mat-icon>
          </button>

          <mat-menu #matMenuHeader="matMenu">
            <button mat-menu-item (click)="syncSeason()" [disabled]="exporting">
              <mat-icon>sync</mat-icon> Sync Season to Cockpit
            </button>
            <button
              mat-menu-item
              (click)="showAggregatedCollectionEffort()"
              [disabled]="
                (authService.hasPermission('read:effort') | async) === false
              "
            >
              <mat-icon>bar_chart</mat-icon> Season Collection Effort
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="matMenuHeader">
            <mat-icon>more_vert</mat-icon>
          </button>

          <i class="material-icons rotating" *ngIf="exporting">sync</i>
        </th>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="actions-cell" mat-cell *matCellDef="let game">
          <a
            mat-icon-button
            [routerLink]="['/games', game._id]"
            title="Game Setup"
            class="small-action-icon"
          >
            <mat-icon>settings</mat-icon>
          </a>

          <a
            mat-icon-button
            [routerLink]="['/games', game._id, 'events']"
            title="Game Events"
            class="small-action-icon"
          >
            <mat-icon>view_list</mat-icon>
          </a>

          <a
            mat-icon-button
            [routerLink]="['/games', game._id, 'time-on-ice']"
            [disabled]="(this.game.videos ?? []).length === 0"
            title="Time on Ice"
            class="small-action-icon"
          >
            <mat-icon>swap_vert</mat-icon>
          </a>

          <a
            mat-icon-button
            class="small-action-icon"
            [routerLink]="['/games', game._id, 'timeline']"
            [disabled]="(this.game.videos ?? []).length === 0"
            title="Timeline"
          >
            <mat-icon>view_timeline</mat-icon>
          </a>
          <app-game-action-menu
            #actionMenu
            [game]="game"
            (gameUpdated)="gameUpdated()"
          ></app-game-action-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="bodyColumns" class="header-row"></tr>
      <tr mat-header-row *matHeaderRowDef="headerColumns" class="filter-row">
      </tr>

      <tr
        mat-row
        *matRowDef="let game; columns: bodyColumns"
        [class.ing-goal-clips]="game.goalClips"
      ></tr>
    </table>

    <mat-progress-bar
      *ngIf="loading"
      mode="indeterminate"
      color="primary"
      style="width: 100%"
    >
    </mat-progress-bar>
    <mat-paginator
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [length]="gameCount"
      [showFirstLastButtons]="true"
      (page)="pageRequested($event)"
    ></mat-paginator>
  </div>

  <button
    mat-raised-button
    color="primary"
    class="new-button"
    [disabled]="(authService.hasPermission('write:games') | async) === false"
    [routerLink]="['/games', 'new']"
  >
    Create Game
  </button>
</div>
