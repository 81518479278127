import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, toPlainObject } from 'lodash/fp';
import { firstValueFrom } from 'rxjs';

import {
  AggregationStatus,
  CameraAngle,
  CVPipelineStatus,
  DataSet,
  FinalizationStatus,
  Game,
  GameOfficial,
  GameOfficialInputField,
  GameProgress,
  GameStatus,
  GoalClipsStatus,
  IdType,
  OfficialRoleInGame,
  SeasonType,
  SihfGameStatus,
  Video,
  VideoVariantEnum
} from '../domain/game';
import { League } from '../domain/league';
import { Official } from '../domain/official';
import { Player } from '../domain/player';
import { Team } from '../domain/team';
import { AlertService } from '../services/alert.service';
import { deepDiffObj } from '../services/deep-diff-obj';
import { GameTimeService } from '../services/game-time.service';
import { GameService, VideoDownloadOptions } from '../services/game.service';
import { LeagueService } from '../services/league.service';
import { OfficialService } from '../services/official.service';
import { PlayerService } from '../services/player.service';
import { SeasonService } from '../services/season.service';
import { TeamService } from '../services/team.service';
import { Stream, VideoService } from '../services/video.service';
import { VideoDownloadDialogComponent } from '../video-download-dialog/video-download-dialog.component';
import { CustomPlayerInputComponent } from '../custom-player-input/custom-player-input.component';

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.css']
})
export class GameDetailsComponent implements OnInit, AfterContentChecked {
  gameStatusOptions = Object.values(GameStatus);
  gameProgressOptions = Object.values(GameProgress);
  aggregationStatusOptions = Object.values(AggregationStatus);
  finalizationStatusOptions = Object.values(FinalizationStatus);
  goalClipsStatusOptions = Object.values(GoalClipsStatus);
  readonly seasonTypes = Object.values(SeasonType);
  readonly idTypes = Object.values(IdType);
  readonly dataSets = Object.values(DataSet);
  readonly cameras = Object.entries(CameraAngle);
  readonly variants = Object.values(VideoVariantEnum);

  sihfGameStatus = SihfGameStatus;
  sihfGameStatusKeys = Object.keys(SihfGameStatus)
    .filter((k) => !isNaN(Number(k)))
    .map((k) => +k);

  readonly currentDate = new Date();
  readonly usersLocalTimezoneShortName = this.currentDate
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
    .substring(4);

  seasons: number[] = [];
  leagues: League[] = [];
  game: Game = new Game({});
  gameUnmodified: Game;
  teams: Team[] = [];
  liveStreams: Stream[] = [];

  @ViewChildren(CustomPlayerInputComponent)
  customPlayerInputs: QueryList<CustomPlayerInputComponent>;

  readonly lines = [
    'FirstLine',
    'SecondLine',
    'ThirdLine',
    'FourthLine',
    'FifthLine'
  ];
  readonly gameOfficialFields: GameOfficialInputField[] = [
    { label: 'Head Official 1', role: 'head', sequenceNumber: 1 },
    { label: 'Head Official 2', role: 'head', sequenceNumber: 2 },
    { label: 'Linesperson 1', role: 'linesperson', sequenceNumber: 1 },
    { label: 'Linesperson 2', role: 'linesperson', sequenceNumber: 2 }
  ];
  officials: Official[] = [];

  showEssential: boolean;
  showCollection: boolean;
  showStatus: boolean;
  showVideos: boolean;
  showOfficials: boolean;
  showLineup: boolean;
  showAssistance: boolean;

  homeTeamPlayers: Player[] = [];
  awayTeamPlayers: Player[] = [];
  customLineup = false;
  mediaServers = [];

  constructor(
    private gameService: GameService,
    private leagueService: LeagueService,
    private teamService: TeamService,
    private playerService: PlayerService,
    private officialService: OfficialService,
    private gameTimeService: GameTimeService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private seasonService: SeasonService,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  async ngOnInit() {
    this.seasons = await firstValueFrom(this.seasonService.getSeasons());
    this.game = this.route.snapshot.data['game'];
    this.gameUnmodified = cloneDeep(this.game);
    this.onDataSetChange(this.game.dataSet);
    this.setupPanels();
    if (this.game._id) {
      this.gameTimeService.init(this.game._id);
    } else {
      this.game.date = new Date().toISOString();
      this.game.season = this.seasons[0].toString();
      this.game.seasonType = SeasonType.REGULAR;
      this.game.idType = IdType.MASTER;
      this.game.dataSet = DataSet.LITE;
    }
    if ((this.game.videos ?? []).length === 0) {
      this.game.videos = [];
    }
    this.teams = await this.teamService.getTeams(
      this.game.league,
      this.game.season,
      this.game.idType
    );
    // sort teams by fullName, shortName
    this.teams.sort((a, b) => a.fullName.localeCompare(b.fullName));

    if (this.game.homeTeam) {
      const homeTeam = this.findTeamByShortName(this.game.homeTeam);
      if (homeTeam) {
        this.homeTeamPlayers = await this.playerService.getPlayersByTeam(
          homeTeam.id,
          this.game.idType
        );
      }
    }
    if (this.game.awayTeam) {
      const awayTeam = this.findTeamByShortName(this.game.awayTeam);
      if (awayTeam) {
        this.awayTeamPlayers = await this.playerService.getPlayersByTeam(
          awayTeam.id,
          this.game.idType
        );
      }
    }
    this.customLineup =
      this.homeTeamPlayers?.length === 0 && this.awayTeamPlayers?.length === 0;
    this.leagues = await firstValueFrom(this.leagueService.getLeagues());
    this.videoService
      .listServers()
      .subscribe((servers) => (this.mediaServers = servers));

    this.officials = await this.fetchOfficials(this.game.getOfficialIds());
  }

  private async fetchOfficials(officialIds: number[]) {
    if (officialIds && officialIds.length > 0) {
      return firstValueFrom(
        this.officialService.fetchByInternalIds(officialIds)
      );
    } else {
      return [];
    }
  }

  setupPanels() {
    this.showEssential = this.game.status === GameStatus.NEW;
    this.showCollection = this.game.status === GameStatus.NEW;
    this.showVideos =
      this.game.status === GameStatus.NEW ||
      this.game.progress === GameProgress.ENDED;
    this.showLineup = this.game.status === GameStatus.NEW;
    this.showOfficials = this.game.status === GameStatus.NEW;
    this.showAssistance =
      this.game.status === GameStatus.NEW || this.game.cvPipeline?.length > 0;
    this.showStatus = true;
  }

  async onLeagueChange(league: string): Promise<void> {
    this.resetLineUp();
    await this.loadTeams(league, this.game.season);
    this.cleanTeams();
  }

  async onSeasonChange(season: string): Promise<void> {
    this.resetLineUp();
    await this.loadTeams(this.game.league, season);
    this.cleanTeams();
  }

  cleanTeams() {
    delete this.game.homeTeamId;
    delete this.game.homeTeam;
    delete this.game.awayTeamId;
    delete this.game.awayTeam;
  }

  get selectedLeague() {
    return this.leagues.find((l) => l.sihfId === this.game.league);
  }

  async onIdTypeChange(idType: string) {
    const homeTeam = this.findTeamByShortName(this.game.homeTeam);
    const awayTeam = this.findTeamByShortName(this.game.awayTeam);

    await Promise.all([
      this.game.homeTeam ? this.onHomeTeamChange(homeTeam) : Promise.resolve(),
      this.game.awayTeam ? this.onAwayTeamChange(awayTeam) : Promise.resolve()
    ]);
  }

  async onHomeTeamChange(team: Team) {
    if (!team) {
      this.game.homeTeam = null;
      this.game.homeTeamId = null;
      return;
    }
    const homeTeamId =
      team[this.teamService.getFieldNameByIdType(this.game.idType)];
    this.game.homeTeam = team.shortName;
    this.game.homeTeamId = homeTeamId;
    await this.loadHomeTeamPlayers(homeTeamId);
  }

  async onAwayTeamChange(team: Team) {
    if (!team) {
      this.game.awayTeam = null;
      this.game.awayTeamId = null;
      return;
    }
    const awayTeamId =
      team[this.teamService.getFieldNameByIdType(this.game.idType)];
    this.game.awayTeam = team.shortName;
    this.game.awayTeamId = awayTeamId;
    await this.loadAwayTeamPlayers(awayTeamId);
  }

  handlePlayerSelect(player: Player | null, positionKey: string) {
    this.game[positionKey + 'ID'] = player?.id ?? '';
    this.game[positionKey] = this.formatPlayer(player);
  }

  private formatPlayer(player: Player | null) {
    if (!player) {
      return '';
    }
    const jerseyNumber = player.jerseyNumber ? `${player.jerseyNumber} - ` : '';
    return jerseyNumber.concat(`${player.lastName} ${player.firstName}`);
  }

  private findTeamByShortName(teamShortName: string) {
    return this.teams.find((t) => t.shortName === teamShortName);
  }

  private resetLineUp() {
    this.teams = [];
    this.homeTeamPlayers = [];
    this.awayTeamPlayers = [];
  }

  private async loadTeams(leagueId: string, season: string) {
    this.teams = await this.teamService.getTeams(
      leagueId,
      season,
      this.game.idType
    );
  }

  private async loadHomeTeamPlayers(teamId: string) {
    if (teamId != null) {
      this.homeTeamPlayers = await this.playerService.getPlayersByTeam(
        teamId,
        this.game.idType
      );
    }
  }

  private async loadAwayTeamPlayers(teamId: string) {
    if (teamId != null) {
      this.awayTeamPlayers = await this.playerService.getPlayersByTeam(
        teamId,
        this.game.idType
      );
    }
  }

  get isGameNew() {
    return !this.game._id;
  }

  async apply(): Promise<boolean> {
    try {
      this.checkCustomLineup();
      this.checkDuplicates(this.game.getAllPlayers(), 'player');
      this.checkDuplicates(
        this.game
          .getAllPlayersObj()
          .map((p) => p.playerId)
          .filter((playerId) => playerId),
        'player'
      );
      this.checkDuplicates(
        this.game
          .getOfficialIds()
          .map((id) => this.officials.find((o) => o.id === id))
          .filter((o) => !!o)
          .map(
            (o) =>
              `${o.lastName} ${o.firstName} (SIHF-ID: ${o.sihfId}, ID: ${o.id})`
          ),
        'official'
      );
      this.checkGameUrls(this.game);
    } catch (e) {
      this.alertService.showError('Save game failed: ' + e.message);
      return false;
    }

    if (this.isGameNew) {
      try {
        await this.gameService.create(this.game).toPromise();
        return true;
      } catch (error) {
        console.error('create game failed: ', error);
        this.alertService.showError('Could not create game: ' + error.message);
        return false;
      }
    } else {
      try {
        const changes = deepDiffObj(
          toPlainObject(this.gameUnmodified),
          toPlainObject(this.game)
        );
        await this.gameService.update(this.game._id, changes).toPromise();
        return true;
      } catch (error) {
        console.error('update game failed: ', error);
        this.alertService.showError('Could not update game: ' + error.message);
        return false;
      }
    }
  }

  async save() {
    const successful = await this.apply();
    if (successful) {
      await this.router.navigate(['games']);
    }
  }

  private checkCustomLineup() {
    const invalidPlayerInputs = this.customPlayerInputs
      .toArray()
      .filter((input) => input && !input.isValid());
    if (invalidPlayerInputs.length > 0) {
      throw new Error('invalid players in custom lineup');
    }
  }

  private checkGameUrls(game: Game) {
    if (game.videos && game.videos.length > 0) {
      game.videos.forEach((video) => {
        if (video.url.includes(' ')) {
          throw new Error(`Invalid URL: "${video.url}" contains whitespace.`);
        }
      });
    }
  }

  addVideo(index: number) {
    this.game.videos.splice(index, 0, {
      id: self.crypto.randomUUID(),
      cameraAngle: null,
      url: ''
    });
  }

  async listLiveStreams(type: string, server: string, app: string) {
    if (!type) {
      this.liveStreams = [];
      return;
    }
    if (type === 'antmedia' && (!server || !app)) {
      this.liveStreams = [];
      return;
    }
    this.liveStreams = await firstValueFrom(
      this.videoService.listStreams(type, server, app)
    );
  }

  async addDefaultLiveStreams() {
    this.game.videos = [];
    const cameraAngles = [
      { camera: 'main', webRTC: true },
      { camera: 'tvfeed', webRTC: true },
      { camera: 'behindgoal1', webRTC: true },
      { camera: 'behindgoal2', webRTC: true },
      { camera: 'overgoal1', webRTC: true },
      { camera: 'overgoal2', webRTC: true },
      { camera: 'corner1', webRTC: true },
      { camera: 'corner2', webRTC: true },
      { camera: 'closeup', webRTC: true },
      { camera: 'gameclock', webRTC: true }
    ];
    for (const cam of cameraAngles) {
      const liveStream = this.liveStreams.find(
        (s) => s.streamId === cam.camera
      );
      if (liveStream) {
        await this.addLiveStream(liveStream, cam.webRTC, true);
      }
    }
  }

  calculateOffsetFromMain(liveStream: Stream) {
    const main = this.liveStreams.find((s) => s.streamId === 'main');
    const existingMain = this.game.videos.find((v) => v.streamId === 'main');
    if (!main && !existingMain) {
      return null;
    }
    const mainStartTime = existingMain?.startTime ?? main.startTime;
    return Math.round((liveStream.startTime - mainStartTime) / 1000);
  }

  async liveStreamSelected(streamId: string) {
    if (streamId === 'nl') {
      await this.addDefaultLiveStreams();
      return;
    }
    const stream = this.liveStreams.find((s) => s.streamId === streamId);
    if (!stream) {
      return;
    }
    await this.addLiveStream(stream, true, true);
  }

  async addLiveStream(
    liveStream: Stream,
    addWebRTC = true,
    calcOffset = false
  ) {
    if (this.streamIsAdded(liveStream.streamId)) {
      // remove again
      this.game.videos = this.game.videos.filter(
        (v) => v.streamId !== liveStream.streamId
      );
      return;
    }

    if (liveStream.platform === 'mux' && liveStream.status !== 'active') {
      console.log('Mux stream is not active', liveStream.streamId);
      this.alertService.showError(
        `Mux stream ${liveStream.name} is not active. Please start sending video to the endpoint first.`
      );
      return;
    }

    if (liveStream.platform === 'mux' && liveStream.muxData) {
      const assetDetails = await firstValueFrom(
        this.videoService.getMuxAssetDetails(
          liveStream.muxData.streamId,
          liveStream.muxData.activeAssetId
        )
      );
      liveStream.protocols.push({
        type: 'hls',
        url: `https://stream.mux.com/${assetDetails.playback_ids[0].id}.m3u8`
      });
    }

    const cameraAngle = this.mapStreamToCameraAngle(liveStream.camera);
    const videos = liveStream.protocols
      .map(
        (p) =>
          ({
            id: self.crypto.randomUUID(),
            cameraAngle,
            variant: 'liverecording',
            offset: calcOffset ? this.calculateOffsetFromMain(liveStream) : 0,
            startTime: liveStream.startTime,
            format: p.type,
            streamId: liveStream.streamId,
            url: p.url
          } as Video)
      )
      .filter((v) => v.format !== 'webrtc' || addWebRTC);
    this.game.videos.push(...videos);

    const webRtcUrl = liveStream.protocols.find(
      (p) => p.type === 'webrtc'
    )?.url;
    if (webRtcUrl) {
      await this.videoService
        .connectStreamToGame(this.game._id, webRtcUrl, cameraAngle)
        .toPromise();
    }
  }

  mapStreamToCameraAngle(camera: string) {
    switch (camera) {
      case 'behindgoal1':
        return CameraAngle.BEHIND_GOAL_1;
      case 'behindgoal2':
        return CameraAngle.BEHIND_GOAL_2;
      case 'corner1':
        return CameraAngle.CORNER_1;
      case 'corner2':
        return CameraAngle.CORNER_2;
      case 'overgoal1':
        return CameraAngle.OVER_GOAL_1;
      case 'overgoal2':
        return CameraAngle.OVER_GOAL_2;
      case 'closeup':
        return CameraAngle.CLOSE_UP;
      case 'fallback':
        return CameraAngle.FALLBACK;
      case 'tvfeed':
        return CameraAngle.TV_FEED;
      case 'gameclock':
        return CameraAngle.GAME_CLOCK;
      case 'multiview':
        return CameraAngle.MULTIVIEW;
      case 'panoramic':
        return CameraAngle.PANORAMIC;
      case 'main':
      default:
        return CameraAngle.MAIN;
    }
  }

  streamIsAdded(streamId: string) {
    return !!this.game.videos.find((v) => v.streamId === streamId);
  }

  removeVideo(video: any) {
    const i = this.game.videos.indexOf(video);
    this.game.videos.splice(i, 1);
  }

  videoUrlChanged(video: Video) {
    if (video.url.endsWith('.mp4')) {
      video.format = 'mp4';
    } else if (video.url.endsWith('.m3u8')) {
      video.format = 'hls';
    } else if (video.url.endsWith('.mpd')) {
      video.format = 'dash';
    } else if (video.url.endsWith('.webm') || video.url.endsWith('.mkv')) {
      video.format = 'webm';
    } else if (
      video.url.startsWith('ws://') ||
      video.url.startsWith('wss://')
    ) {
      video.format = 'webrtc';
    }
  }

  async downloadVideo(video: Video) {
    const dialog: MatDialogRef<
      VideoDownloadDialogComponent,
      VideoDownloadOptions
    > = this.dialog.open(VideoDownloadDialogComponent, {
      width: '900px',
      data: { game: this.game, video }
    });
    const downloadOptions = await dialog.afterClosed().toPromise();
    if (!downloadOptions) {
      return;
    }

    this.gameService
      .downloadVideo(this.game._id, video, downloadOptions)
      .subscribe(
        (res) => {
          video.downloadStatus = res.status as any;
          this.alertService.showInfo(`Archive video started`);
        },
        (error) => {
          console.error('download video failed: ', error);
          video.downloadStatus = 'error';
          video.downloadError = error.message;
          this.alertService.showError(
            'Could not download video: ' + error.message
          );
        }
      );
  }

  videoDownloadAvailable(game: Game, video: Video) {
    return game._id && video.url;
  }

  private checkDuplicates(values: string[], entityType: 'player' | 'official') {
    const duplicates = values.filter((p, i) => values.indexOf(p) !== i);
    if (duplicates.length > 0) {
      throw new Error(`duplicate ${entityType} "${duplicates[0]}"`);
    }
  }

  displayName(camera: string) {
    return camera.replace(/_/g, ' ');
  }

  onDataSetChange(value: string) {
    if (value !== DataSet.LITE && value !== DataSet.LITE_PLUS) {
      this.gameStatusOptions = this.gameStatusOptions.filter(
        (item) => item !== GameStatus.IN_EXTENDED_COLLECTION
      );
    } else {
      this.gameStatusOptions = Object.values(GameStatus);
      this.game.autoPuckPossessionEvents = false;
    }
  }

  onGameStatusChange(event: string) {
    switch (event) {
      case GameStatus.NEW:
        this.game.progress = GameProgress.BEFORE_START;
        break;
      case GameStatus.COMPLETE:
        this.game.progress = GameProgress.ENDED;
        break;
    }
  }

  updateFinalizationStatus(status: string) {
    if (this.game.finalization) {
      this.game.finalization.status = status as FinalizationStatus;
    }
  }

  taskId(taskArn: string) {
    return taskArn?.split('/').pop();
  }

  duration(start: Date, stop: Date) {
    if (!start || !stop) {
      return null;
    }
    return (new Date(stop).getTime() - new Date(start).getTime()) / 1000;
  }

  logsUrl(item: CVPipelineStatus) {
    const awsRegion = 'eu-central-1';
    if (this.cvTaskType(item) === 'ECS') {
      const logGroup = this.cloudWatchEncode('/ecs/CVPipeline');
      const logStream = this.cloudWatchEncode(
        `ecs/CVPipeline/${this.taskId(item.taskArn)}`
      );
      const url =
        `https://${awsRegion}.console.aws.amazon.com/cloudwatch/home?region=${awsRegion}` +
        `#logsV2:log-groups/log-group/${logGroup}/log-events/${logStream}`;
      return this.sanitizer.bypassSecurityTrustUrl(url);
    } else if (this.cvTaskType(item) === 'BATCH') {
      const url = `https://${awsRegion}.console.aws.amazon.com/batch/home?region=${awsRegion}#jobs/ec2/detail/${item.jobId}`;
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  }

  private cloudWatchEncode(url: string) {
    return url.replace(/\//g, '$252F');
  }

  tagsChange(tags: string[]) {
    this.game.tags = tags ?? [];
  }

  isCVError(item: CVPipelineStatus) {
    return item.lastStatus === 'FAILED';
  }

  deleteCVPipelineRecord(item: CVPipelineStatus) {
    const i = this.game.cvPipeline.findIndex(
      (s) =>
        (item.taskArn && s.taskArn === item.taskArn) ||
        (item.jobId && s.jobId === item.jobId)
    );
    this.game.cvPipeline.splice(i, 1);
  }

  cvTaskType(p: CVPipelineStatus) {
    if (p.taskArn) {
      return 'ECS';
    } else if (p.jobId) {
      return 'BATCH';
    }
  }

  get teamChangeDisabled() {
    return [
      GameStatus.IN_COLLECTION,
      GameStatus.IN_EXTENDED_COLLECTION
    ].includes(this.game.status);
  }

  findGameOfficial(
    officialRoleInGame: OfficialRoleInGame,
    sequenceNumber: number
  ) {
    const gameOfficial = this.game.officials.find(
      (go: GameOfficial) =>
        go.officialRoleInGame === officialRoleInGame &&
        go.sequenceNumber === sequenceNumber
    );
    return gameOfficial;
  }

  findOfficial(officialRoleInGame: OfficialRoleInGame, sequenceNumber: number) {
    const gameOfficial = this.findGameOfficial(
      officialRoleInGame,
      sequenceNumber
    );
    return gameOfficial
      ? this.officials.find((o) => o.id === gameOfficial.officialId)
      : undefined;
  }

  async updateGameOfficial(
    officialId: number | undefined,
    role: OfficialRoleInGame,
    sequenceNumber: number
  ) {
    this.officials = await this.fetchOfficials(this.game.getOfficialIds());
    const gameOfficial = this.findGameOfficial(role, sequenceNumber);
    if (gameOfficial) {
      gameOfficial.officialId = officialId;
      if (officialId) {
        delete gameOfficial.unknownOfficialInfo;
      }
      this.cleanupGameOfficials();
    } else if (officialId) {
      this.game.officials.push({
        officialRoleInGame: role,
        sequenceNumber,
        officialId
      });
    } else if (!officialId) {
      this.cleanupGameOfficials();
    }
  }

  private cleanupGameOfficials() {
    this.game.officials = this.game.officials.filter(
      (go) => !!go.officialId || !!go.unknownOfficialInfo
    );
  }

  getCVWarnings(status: CVPipelineStatus) {
    if (!status.warnings) {
      return this.game['cv-pipeline']
        ? this.game['cv-pipeline']['period_' + status.period]?.warnings
        : undefined;
    }
    return status.warnings;
  }
}
