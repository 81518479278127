import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CvPipelineDialogComponent } from '../cv-pipeline-dialog/cv-pipeline-dialog.component';
import {
  AggregationStatus,
  FinalizationStatus,
  Game,
  GameProgress,
  GameStatus
} from '../domain/game';
import { DuplicateGameDialogComponent } from '../duplicate-game-dialog/duplicate-game-dialog.component';
import { GameExportDialogComponent } from '../game-export-dialog/game-export-dialog.component';
import { GameQualityReportDialogComponent } from '../game-quality-report-dialog/game-quality-report-dialog.component';
import { GameQualityDialogComponent } from '../game-quality-dialog/game-quality-dialog.component';
import { GameSyncDialogComponent } from '../game-sync-dialog/game-sync-dialog.component';
import { GameValidationDialogComponent } from '../game-validation-dialog/game-validation-dialog.component';
import { ImportSIHFGoalsDialogComponent } from '../import-sihf-goals-dialog/import-sihf-goals-dialog.component';
import { LineupChangeDialogComponent } from '../lineup-change-dialog/lineup-change-dialog.component';
import { AlertService } from '../services/alert.service';
import { EventService } from '../services/event.service';
import { GameService } from '../services/game.service';
import { VideoService } from '../services/video.service';
import { CollectionEffortComponent } from '../shared/collection-stats/collection-effort.component';
import { FileUploadDialogComponent } from '../shared/file-upload-dialog/file-upload-dialog.component';
import { PlayerSummaryComponent } from '../shared/player-summary/player-summary.component';
import { UpdateFOINTDialogComponent } from '../update-foint-dialog/update-foint-dialog.component';
import { VideoTimeOffsetDialogComponent } from '../video-time-offset-dialog/video-time-offset-dialog.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-game-action-menu',
  templateUrl: './game-action-menu.component.html',
  styleUrls: ['./game-action-menu.component.css']
})
export class GameActionMenuComponent {
  protected readonly GameProgress = GameProgress;
  readonly gameStatus = GameStatus;

  exporting = false;

  @Input()
  game: Game;

  @Output()
  gameUpdated = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private gameService: GameService,
    public authService: AuthService,
    private eventService: EventService,
    private videoService: VideoService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  duplicate(game: Game) {
    const dialogRef = this.dialog.open(DuplicateGameDialogComponent, {
      data: { game }
    });
    dialogRef.afterClosed().subscribe((duplicateEventTypes) => {
      if (duplicateEventTypes) {
        this.gameService
          .duplicateGame(game._id, duplicateEventTypes)
          .subscribe(async (newGame: Game) => {
            this.alertService.showInfo('Game duplicated', 'View Game Setup');
            const previousRouteReuseStrategy =
              this.router.routeReuseStrategy.shouldReuseRoute;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';

            await this.router.navigate(['/games', newGame._id]);

            this.router.routeReuseStrategy.shouldReuseRoute =
              previousRouteReuseStrategy;
            this.router.onSameUrlNavigation = 'ignore';
          });
      }
    });
  }

  delete(game: Game) {
    if (
      confirm(
        `Are you sure to delete the game ${game.date.substr(0, 10)} ${
          game.homeTeam
        }-${game.awayTeam} including all events ?`
      )
    ) {
      this.gameService.delete(game).subscribe({
        next: async () => {
          this.gameUpdated.emit();
          await this.router.navigate(['/games']);
        },
        error: (error) => {
          console.log('delete failed: ' + error.message);
        }
      });
    }
  }

  updateStatus(game: Game, status: GameStatus): void {
    this.gameService.updateStatus(game._id, status).subscribe(
      () => {
        game.status = status;
        if (status === GameStatus.COMPLETE) {
          if (game.isTrainingGame) {
            this.snackbar.open(`Game is not synced: it's a training game`);
            return;
          }

          this.exporting = true;
          this.gameService
            .syncGame(game._id, 'all')
            .pipe(finalize(() => (this.exporting = false)))
            .subscribe(
              async (res) => {
                console.log('Sync game succeeded');
                this.gameUpdated.emit();
                if (res.message) {
                  alert(res.message);
                }
              },
              (err) => {
                alert('Sync game failed: ' + err.error.message);
              }
            );
        }
      },
      (err) =>
        this.alertService.showError('Update status failed: ' + err.message)
    );
  }

  validateGame(game: Game): void {
    this.exporting = true;
    this.gameService
      .validateGame(game._id)
      .pipe(finalize(() => (this.exporting = false)))
      .subscribe(
        (validations) => {
          this.game.hasErrors = validations.some((v) => v.status === 'error');
          this.game.hasWarnings = validations.some(
            (v) => v.status === 'warning'
          );
          this.dialog.open(GameValidationDialogComponent, {
            width: '400px',
            data: { game, validations }
          });
        },
        (err) => {
          alert('Validate game failed: ' + err.error.message);
        }
      );
  }

  adjustLineup(game: Game): void {
    this.dialog.open(LineupChangeDialogComponent, {
      width: '800px',
      data: { game },
      panelClass: 'lineup-dialog-container'
    });
  }

  splitShiftsByStrengthState(game: Game) {
    this.eventService.splitShiftsByStrengthState(game._id).subscribe(
      (res) => {
        this.alertService.showInfo(res.message);
      },
      (err) => {
        this.alertService.showError(
          'Update strength states failed: ' + err.message
        );
      }
    );
  }

  applyVideoTimeOffset(game: Game) {
    const dialogRef = this.dialog.open(VideoTimeOffsetDialogComponent, {
      width: '640px',
      data: { game }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.offset && result.offset !== 0) {
        this.eventService
          .applyVideoTimeOffset(game._id, result.period, result.offset)
          .subscribe(
            (res) => {
              alert(res.message);
            },
            (err) => {
              alert('Apply videoTime offset failed: ' + err.message);
            }
          );
      }
    });
  }

  recalculateFOINTGameTime(game: Game) {
    this.dialog
      .open(UpdateFOINTDialogComponent, {
        data: {
          gameName: this.gameService.getGameTitle(game)
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.eventService
            .recalculateFOINTGameTime(game._id, result.periods)
            .subscribe({
              next: (res) => {
                this.snackbar.open(res.message);
              },
              error: (err) => {
                this.snackbar.open(
                  'Recalculate FOINT game time failed: ' + err.message,
                  'Close'
                );
              }
            });
        }
      });
  }

  updateGameTimes(game: Game) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Interpolating GameTimes for ${this.gameService.getGameTitle(
            game
          )}`,
          message: `Game time will be derived from FO/INT for all events, except Face-Offs and Interruptions`,
          okButtonCaption: 'Interpolate'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.eventService.updateGameTime(game._id).subscribe(
            (res) => {
              alert(res.message);
            },
            (err) => {
              alert('Update game times failed: ' + err.message);
            }
          );
        }
      });
  }

  updateVideoTimes(game: Game) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Interpolating VideoTimes for ${this.gameService.getGameTitle(
            game
          )}`,
          message: `Video time will be derived from FO/INT for all events, except Face-Offs and Interruptions`,
          okButtonCaption: 'Interpolate'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.eventService.updateVideoTime(game._id).subscribe(
            (res) => {
              alert(res.message);
            },
            (err) => {
              alert('Update video times failed: ' + err.message);
            }
          );
        }
      });
  }

  updateStrengthStates(game: Game) {
    this.eventService.updateStrengthState(game._id).subscribe(
      (res) => {
        alert(res.message);
      },
      (err) => {
        alert('Update strength states failed: ' + err.message);
      }
    );
  }

  finalizeLiveStreamLogs(game: Game) {
    const region = 'eu-central-1';
    if (!game.finalization) {
      return null;
    }
    const { commandId, instanceId } = game.finalization;
    return (
      `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/$252Fantmedia/` +
      `log-events/${commandId}$252F${instanceId}$252Faws-runShellScript$252Fstdout`
    );
  }

  archiveLiveStream(game: Game) {
    const gameName = `${game.date.substr(0, 10)} ${game.homeTeam}-${
      game.awayTeam
    }`;

    if (
      !confirm(
        `Please confirm the action "archive live stream" for the game "${gameName}"`
      )
    ) {
      return;
    }

    this.videoService.archiveLiveStream(game._id).subscribe(
      () => {
        game.finalization = {
          status: FinalizationStatus.IN_PROGRESS,
          start: new Date(),
          commandId: null,
          instanceId: null
        };
        this.alertService.showInfo(
          `Archive Live Stream started for game '${gameName}'`
        );
      },
      (err) => {
        game.finalization = {
          status: FinalizationStatus.FAILED,
          start: new Date(),
          commandId: null,
          instanceId: null
        };
        this.alertService.showError(
          `Archive Live Stream failed for game '${gameName}': ${err.error?.message}`
        );
      }
    );
  }

  manageMediaServer(game: Game, action: 'shutdown' | 'restart') {
    const gameName = `${game.date.substr(0, 10)} ${game.homeTeam}-${
      game.awayTeam
    }`;

    if (
      !confirm(
        `Please confirm the action "${action}" for the media server "${gameName}"`
      )
    ) {
      return;
    }

    this.videoService.manageMediaServer(game._id, action).subscribe(
      () => {
        this.alertService.showInfo(
          `Executing action ${action} started for game '${gameName}'`
        );
      },
      (err) => {
        this.alertService.showError(
          `Action ${action} failed for game '${gameName}': ${err.error?.message}`
        );
      }
    );
  }

  finalizationComplete(game: Game) {
    game.finalization = {
      ...game.finalization,
      status: FinalizationStatus.COMPLETE
    };
    // TODO: check download status of removed videos
    game.videos = game.videos.filter(
      (v) => v.format === 'mp4' || v.url.startsWith('https://videos.49ing.ch')
    );
    this.gameService
      .update(game._id, {
        finalization: game.finalization,
        videos: game.videos
      })
      .subscribe();
  }

  downloadShiftsCSV(game: Game) {
    this.gameService.getGameShiftsAsCSV(game._id).subscribe((data) => {
      const filename = 'shifts_' + game._id + '.csv';
      this.downloadFile(data, game, filename, 'iso-8859-1');
    });
  }

  downloadGameEventsCSV(game: Game) {
    this.gameService.getGameEventsAsCSV(game).subscribe((res) => {
      if (res) {
        const filename =
          'game_events_' +
          game.date +
          '_' +
          game.homeTeam +
          '-' +
          game.awayTeam +
          '_by_' +
          game.eventCollectorName +
          '.csv';
        this.downloadFile(res, game, filename, 'iso-8859-1');
      }
    });
  }

  uploadGameEventsCSV(game: Game) {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: '320px',
      height: '200px',
      data: { game }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Importing Game Events', { gameId: game._id });
      this.gameService.importGameEventsAsCSV(game, result.data).subscribe(
        async () => {
          console.log('Upload complete');
          this.gameUpdated.emit();
        },
        (err) => {
          console.error('failed to upload CSV: ' + err.message);
        }
      );
    });
  }

  downloadFile(data: string, game: Game, filename: string, encoding = 'utf-8') {
    let blob;
    if (encoding === 'utf-8') {
      blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    } else {
      blob = new Blob([data], { type: 'text/csv;charset=' + encoding + ';' });
    }
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  sihfExportDialog(game: Game) {
    this.dialog.open(GameExportDialogComponent, {
      width: '1000px',
      data: { game }
    });
  }

  runAggregation(game: Game) {
    const gameName = `${game.date.substr(0, 10)} ${game.homeTeam}-${
      game.awayTeam
    }`;
    this.gameService.runAggregation(game._id).subscribe(
      (res) => {
        game.aggregationStatus = AggregationStatus.IN_PROGRESS;
        game.aggregationTaskArn = res.aggregationTaskArn;
        this.alertService.showInfo(
          `Aggregation started for game '${gameName}'`
        );
      },
      (err) => {
        game.aggregationStatus = AggregationStatus.FAILED;
        this.alertService.showError(
          `Aggregation failed for game '${gameName}': ${err.message}`
        );
      }
    );
  }

  syncGame(game: Game): void {
    const dialog = this.dialog.open(GameSyncDialogComponent, {
      width: '400px',
      data: { game }
    });
    dialog.afterClosed().subscribe((period) => {
      if (!period) {
        return;
      }

      this.exporting = true;
      this.gameService
        .syncGame(game._id, period)
        .pipe(finalize(() => (this.exporting = false)))
        .subscribe(
          async (res) => {
            console.log('Sync game succeeded');
            this.gameUpdated.emit();
            if (res.message) {
              alert(res.message);
            }
          },
          (err) => {
            alert('Sync game failed: ' + err.error.message);
          }
        );
    });
  }

  runCVPipeline(game: Game): void {
    const dialog = this.dialog.open(CvPipelineDialogComponent, {
      width: '400px',
      data: { game }
    });
    dialog.afterClosed().subscribe(async ({ liveMode, periods, phase }) => {
      if (!periods || !phase) {
        return;
      }
      await firstValueFrom(
        this.gameService.runCVPipeline(game._id, liveMode, periods, phase)
      );
      game.isCVProcessed = true; // update in-memory state
      this.alertService.showInfo(
        'CV-Pipeline started for ' + game.homeTeam + ' - ' + game.awayTeam
      );
    });
  }

  showGameCollectionQuality(game: Game): void {
    this.dialog.open(GameQualityReportDialogComponent, {
      width: '1400px',
      data: {
        game
      }
    });
  }

  showGameCollectionEffort(game: Game): void {
    this.dialog.open(CollectionEffortComponent, {
      width: '900px',
      data: {
        game
      }
    });
  }

  showTimeOnIceQualityReport(game: Game): void {
    this.gameService.timeOnIceReport(game._id).subscribe((report) => {
      this.dialog.open(GameQualityDialogComponent, {
        width: '400px',
        data: { game, report }
      });
    });
  }

  showPlayerSummary(game: Game): void {
    this.dialog.open(PlayerSummaryComponent, {
      width: '640px',
      data: {
        game
      }
    });
  }

  exportGoalClips(game: Game) {
    this.eventService.exportGoalClips(game._id).subscribe({
      next: ({ message }) => {
        this.alertService.showInfo(message);
      },
      error: (err) => {
        this.alertService.showError('Export goal clips failed: ' + err.message);
      }
    });
  }

  importGoalsSihfReporter(gameId: string) {
    const dialogRef = this.dialog.open<
      ImportSIHFGoalsDialogComponent,
      any,
      { scorer: boolean; assists: boolean }
    >(ImportSIHFGoalsDialogComponent, {
      data: { gameId }
    });
    dialogRef.afterClosed().subscribe((options) => {
      if (!options) {
        return;
      }
      this.gameService
        .importGoalsFromSihfReporter(gameId, options)
        .subscribe(async (res) => {
          if (res.message) {
            this.alertService.showInfo(res.message);
            return;
          }
          await this.router.navigate(['/games', gameId, 'events'], {
            queryParams: { eventType: 'shot', shotOutcome: 'goal' }
          });
        });
    });
  }

  importPenaltiesSihfReporter(gameId: string) {
    this.gameService
      .importPenaltiesFromSihfReporter(gameId)
      .subscribe(async (res) => {
        if (res.message) {
          this.alertService.showInfo(res.message);
          return;
        }
        await this.router.navigate([gameId, 'events'], {
          relativeTo: this.route,
          queryParams: { eventType: 'penalty' }
        });
      });
  }
}
